body::-webkit-scrollbar {
  display: none;
}

.pulse {
  height: 32px;
  width: 32px;
  margin: 0 auto;
  animation-name: stretch;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;

  cursor: pointer;
}

@keyframes stretch {
  0% {
    transform: scale(1);
    border-radius: 100%;
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1.5);
  }
}

.router-radius {
  background-image: -webkit-radial-gradient(
    circle,
    rgba(255, 0, 0, 0.5),
    rgba(255, 0, 0, 0.01)
  );
}

/* .pozzetto-tooltip {
  margin-top: 16px;
}

.pozzetto-tooltip:after {
  content: "";
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  transform: rotate(0deg);
  right: 233px;
  top: 36px;
} */
